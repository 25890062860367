import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { validateEmail } from 'src/app/utils/utils';
import { TENANT_USER_ROLES } from 'src/models/users';
import { TenantUserRole } from 'src/models/users';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { CustomersService } from 'src/services/customers.service';
import { MessageService } from 'src/services/message.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.page.html',
  styleUrls: ['./add-member.page.scss'],
})
export class AddMemberPage {
  isLoading = false;
  email: string;
  name: string;
  phoneNumber: string;
  notes: string;
  sendEmail = true;
  organizerId: string;
  hidePhoneNumber = false;
  hideWelcomeOption = true;

  role: TenantUserRole = TENANT_USER_ROLES.STANDARD;

  ROLE_OPTIONS = [
    this.authService.isTenantOwner
      ? {
          label: 'Owner',
          value: TENANT_USER_ROLES.OWNER,
          icon: 'shield-checkmark-outline',
        }
      : undefined,
    {
      label: 'Admin',
      value: TENANT_USER_ROLES.ADMIN,
      icon: 'shield-outline',
    },
    {
      label: 'Standard',
      value: TENANT_USER_ROLES.STANDARD,
    },
  ].filter(Boolean);

  constructor(
    navParams: NavParams,
    public authService: AuthService,
    private modalCtrl: ModalController,
    private msgSrvc: MessageService,
    private apiService: ApiService,
    private customersService: CustomersService,
    private analyticsService: AnalyticsService,
  ) {
    this.organizerId = navParams.get('organizerId');
    this.hidePhoneNumber = authService.phoneNumberRequirement === 'none';
    this.hideWelcomeOption = !navParams.get('welcomeEmailEnabled');
    if (navParams.get('isAdmin')) {
      this.role = TENANT_USER_ROLES.ADMIN;
    }
  }

  async submit() {
    const cleanEmail = validateEmail(this.email);

    if (!cleanEmail) {
      await this.msgSrvc.show('Whoops! Please enter a valid email.');

      return;
    }

    this.isLoading = true;

    try {
      if (this.organizerId) {
        await this.customersService.upsertCustomerMember({
          customerId: this.organizerId,
          email: cleanEmail,
          name: this.name,
          phoneNumber: this.phoneNumber || undefined,
          notes: this.notes || '',
          muteWelcomeEmail: !this.sendEmail,
          following: true,
        });

        await this.msgSrvc.show('Member added!');
      } else {
        await this.apiService.postPromise(`/users/invite`, {
          email: cleanEmail,
          name: this.name,
          notes: this.notes || '',
          role: this.role,
          muteWelcomeEmail: !this.sendEmail,
        });

        const invitationMessage = 'Invitation sent!';
        const directAddMessage = 'New team member added!';
        const successMessage = this.sendEmail ? invitationMessage : directAddMessage;

        await this.msgSrvc.show(successMessage);
      }

      this.analyticsService.trackEvent('Add Member', `Invite Team Member`, {
        email: cleanEmail,
      });

      this.dismiss(true);
    } catch (err) {
      await this.msgSrvc.showError(err);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss(success?: boolean) {
    const data =
      success && this.email
        ? {
            email: this.email,
            name: this.name,
            notes: this.name,
          }
        : null;
    this.modalCtrl.dismiss(data);
  }

  roleCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === null);
  }
}
